import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from './Login';
import Products from './Pages/Products';
import Branches from './Pages/Branches';
import Users from './Pages/Users';
import Orders from './Pages/Orders/Orders';
import Sales from './Pages/Sales/Sales';
import OrderSummary from './Pages/Reports/OrderSummary';
import SaleSummary from './Pages/Reports/SaleSummary';
import StoreBalance from './Pages/Reports/StoreBalance';
import Categories from './Pages/Categories';
import Dashboard from './Pages/Dashboard';
import Sliders from './Pages/General/Sliders';
import Ads from './Pages/General/Ads';
import Clients from './Pages/General/Clients';
import General from './Pages/General/General';
import Social from './Pages/General/Social';
import Services from './Pages/General/Services';
import Roles from './Pages/Role';
import Customers from './Customers';
import SaleItems from './Pages/Sales/SaleItems';
import Invoice from './Pages/Sales/Invoice';

if (!localStorage.getItem('nav')) { localStorage.setItem('nav', 'Main,Report'); }
const acc = localStorage.getItem('nav').split(',');

const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} acc={acc} />
                </div>
                .0
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Dashboard icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sales",
        element: <APP><Sales icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sale-items/:id",
        element: <APP><SaleItems icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/invoice/:id",
        element: <APP><Invoice icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/orders",
        element: <APP><Orders icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/users",
        element: <APP><Users icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/customers",
        element: <APP><Customers icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/branches",
        element: <APP><Branches icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/categories",
        element: <APP><Categories icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/products",
        element: <APP><Products icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/order-summary",
        element: <APP><OrderSummary icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/sale-summary",
        element: <APP><SaleSummary icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/store-balance",
        element: <APP><StoreBalance icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sliders",
        element: <APP><Sliders icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/ads",
        element: <APP><Ads icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/clients",
        element: <APP><Clients icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/general",
        element: <APP><General icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/socials",
        element: <APP><Social icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services",
        element: <APP><Services icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/roles",
        element: <APP><Roles icon={icon} acc={acc} /></APP>,
        errorElement: <ErrorPage />,
    },

]);

export default Routes;