import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from 'comma-number';
import { Link, useParams } from "react-router-dom";
import Barcode from "../../../JsBarcode";

const SaleItems = ({ icon, acc }) => {
    const saleId = useParams().id
    const [sale, setSale] = useState([])
    const [data, setData] = useState([]);
    const [products, setProducts] = useState([]);

    const getData = async () => {
        const { data: sale } = await axios.get(`/sale/${saleId}`);
        setSale(sale[0]);
        const { data } = await axios.get(`/sale-item/${saleId}`);
        setData(data);
        const { data: products } = await axios.get('/product');
        setProducts(products.map((item) => {
            return {
                value: item.id,
                sale_price: item.sale_price,
                label: `#${item.barcode} ${item.product_name}`,
                barcode: item.barcode
            };
        }));

    };
    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {

        // barcode reader
        var barcode = '';
        var interval;
        document.addEventListener('keydown', function (evt) {
            if (interval)
                clearInterval(interval);
            if (evt.code == 'Enter') {
                if (barcode)
                    handleBarcode(barcode);
                barcode = '';
                return;
            }
            if (evt.key != 'Shift')
                barcode += evt.key;
            interval = setInterval(() => barcode = '', 20);
        });
        function handleBarcode(scanned_barcode) {
            document.querySelector('#last-barcode').innerHTML = scanned_barcode;
        }

    }, [data, sale])

    if (!acc.includes('General')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">New Invoice</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <h6 className="card-title">New Invoice</h6>
                                </div>
                                <div className="col-md-6">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.get('/check-order', { params: data }).then((res) => {
                                            if (res.data !== 0) {
                                                axios.post(`/sale-item`, data).then(() => {
                                                    toast.success('Added Successfully');
                                                    getData();
                                                })
                                            } else {
                                                toast.error('this amount not available from your store !')
                                            }
                                        })

                                    }} >
                                        <div className="row">
                                            <input type="hidden" name="sale_id" defaultValue={saleId} />
                                            <input type="hidden" name="branch_id" defaultValue={sale.branch_id} />
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">Products</label>
                                                <Select options={products} name="product_id" placeholder="Select Product" onChange={(e) => { $('#sale_price').val(e.sale_price) }} required />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Qty</label>
                                                <input type="number" step="any" className="form-control" name="qty" placeholder="0.00" defaultValue={0.00} required />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Sale Price</label>
                                                <input type="number" step="any" className="form-control" id="sale_price" name="sale_price" placeholder="0.00" defaultValue={0} required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">Note</label>
                                                <textarea className="form-control" name="note" placeholder="Any Note ..." />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <button type="submit" className="btn btn-primary w-50">Add to Cart <icon.ArrowDown size="18" /></button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>Barcode</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                    <th>Total</th>
                                                    <th>Note</th>
                                                    <th>option</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.product_name}</td>
                                                            <td><Barcode value={item.barcode} /></td>
                                                            <td>{item.qty}</td>
                                                            <td>{commaNumber(item.sale_price)}$</td>
                                                            <td>{commaNumber(item.total_price)}$</td>
                                                            <td>{item.note ? item.note : '-'}</td>
                                                            <td>
                                                                <span type="button" className="badge bg-danger"
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: "Yes, delete it!",
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                axios.delete(`/sale-item/${item.id}`).then(() => {
                                                                                    getData();
                                                                                })
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <icon.Trash size="18" />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Link to="/sales" type="button" className="btn btn-success mt-2" >Save Changes <icon.Save size="18" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleItems;