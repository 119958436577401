import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import commaNumber from 'comma-number';
import Barcode from "../../../JsBarcode";


const OrderSummary = ({ icon, acc }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/order-summary');
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);


    if (!acc.includes('Report')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Order Summary</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Order Summary</h6>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Barcode</th>
                                        <th>Qty</th>
                                        <th>Price (AVG)</th>
                                        <th>Total</th>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.product_name}</td>
                                                <td><Barcode value={item.barcode} /></td>
                                                <td>{item.total_qty}</td>
                                                <td>{commaNumber(item.avg_price)}$</td>
                                                <td>{commaNumber(item.total_price)}$</td>
                                                <td>{item.branch_name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OrderSummary;