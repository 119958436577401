import axios from "axios";
import { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';

const Dashboard = () => {
    const [orders, setOrders] = useState([]);
    const [sales, setSales] = useState([]);

    const getData = async () => {
        const { data: orders } = await axios.get('/stats/orders');
        setOrders(orders);
        const { data: sales } = await axios.get('/stats/sales');
        setSales(sales);
    };

    useEffect(() => {
        getData();
    }, []);

    const productNames = sales.map(sale => sale.product_name);
    const salesData = {
        series: [{
            name: 'Sales',
            data: sales.map(sale => sale.qty),
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: sales.map(sale => new Date(sale.created).toLocaleDateString()),
            },
            tooltip: {
                y: {
                    formatter: function (val, { seriesIndex, dataPointIndex }) {
                        const productName = productNames[dataPointIndex];
                        return `${val} qty - ${productName}`;
                    }
                }
            },
        },
    };


    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'Quantity'
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            }
        }
    });

    const fetchData = async () => {
        try {
            const { data } = await axios.get('/stats/product-summary');
            processChartData(data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    const processChartData = (data) => {
        const categories = data.map(item => item.product_name);
        const orderQty = data.map(item => item.order_qty);
        const saleQty = data.map(item => item.sale_qty);
        const qtyBalance = data.map(item => item.qty_balance);

        setChartData(prevState => ({
            ...prevState,
            series: [
                { name: 'Ordered Qty', data: orderQty },
                { name: 'Sold Qty', data: saleQty },
                { name: 'Available Qty', data: qtyBalance }
            ],
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: categories
                }
            }
        }));
    };

    useEffect(() => {
        fetchData();
    }, []);



    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">Product Summary </h6>
                            </div>
                            <p className="text-muted">Product Summary are activities related to purchasing, selling and Available Products.</p>
                            <div id="chart">
                                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">Sales </h6>
                            </div>
                            <p className="text-muted">Sales are activities related to selling the number of Products.</p>
                            <div id="monthlySalesChart">
                                <ReactApexChart options={salesData.options} series={salesData.series} type="bar" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;