import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";

const Invoice = ({ icon, acc }) => {
    const saleId = useParams().id
    const [sale, setSale] = useState([]);
    const [item, setItem] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/sale/${saleId}`);
        setSale(data[0]);
        const { data: item } = await axios.get(`/sale-item/${saleId}`);
        setItem(item);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {
    }, [sale, item])

    if (!acc.includes('General')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Special pages</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Invoice</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="print">
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 ps-0">
                                        <a href="#" className="noble-ui-logo d-block mt-3">Easy<span>London</span></a>
                                        <p>{sale.branch_name},<br />{sale.phone_no},<br />{sale.branch_address}.</p>
                                        <h5 className="mt-5 mb-2 text-muted">Invoice to :</h5>
                                        <p>{sale.customer_name? sale.customer_name: 'N/A'},<br /> {sale.phone_number? sale.phone_number:'N/A'},<br /> {sale.address?sale.address:'N/A'}.</p>
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">invoice</h4>
                                        <h6 className="text-end mb-5 pb-4"># INV-{sale.id}</h6>
                                        <p className="text-end mb-1">Balance Due</p>
                                        <h4 className="text-end fw-normal">$ {commaNumber(sale.total_sale_amount)}</h4>
                                        <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">Invoice Date :</span> {new Date(sale.created_at).toLocaleDateString()}</h6>
                                        <h6 className="text-end fw-normal"><span className="text-muted">Due Date :</span> {new Date().toLocaleDateString()}</h6>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 d-flex justify-content-center w-100">
                                    <div className="table-responsive w-100">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th className="text-end">Quantity</th>
                                                    <th className="text-end">Unit cost</th>
                                                    <th className="text-end">Total</th>
                                                    <th>Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-start">{index + 1}</td>
                                                            <td className="text-start">{item.product_name}</td>
                                                            <td className="text-end">{item.qty}</td>
                                                            <td className="text-end">{commaNumber(item.sale_price)}$</td>
                                                            <td className="text-end">{commaNumber(item.total_price)}$</td>
                                                            <td className="text-start">{item.note ? item.note : '-'}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="row">
                                        <div className="col-md-6 ms-auto">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Total</td>
                                                            <td className="text-end">$ {commaNumber(sale.total_sale_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Quantity</td>
                                                            <td className="text-end">{sale.total_quantity}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-bold-800">Products</td>
                                                            <td className="text-bold-800 text-end">{sale.total_products}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid w-100">
                                <Link to="/sales" className="btn btn-primary float-end mt-4 ms-2"><icon.CornerDownLeft className="me-3 icon-md" /> Return to Sales</Link>
                                <button type="button" className="btn btn-outline-primary float-end mt-4" onClick={() => window.print()}><icon.Printer className="me-3 icon-md" /> Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;