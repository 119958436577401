import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Roles = ({ icon, acc }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/role');
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const accessItem = ["Main", "Report", "General", "Site Setting"]

    if (!acc.includes('General')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Role</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Role List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Role</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newcategory">New Role</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/role`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Role Name</label>
                                                            <input type="text" className="form-control" name="title" placeholder="Role Name" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Role</th>
                                            <th>Permissions</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        {accessItem.filter(j => j !== item.access).map((i, x) => (
                                                            item.access.includes(i) ? <span key={x} className="badge bg-success mx-1"><icon.Check size="16" /> {i}</span> : <span key={x} className="badge bg-danger mx-1"><icon.X size="16" /> {i}</span>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {item.title === "Admin" ? "" :
                                                            <>
                                                                <span type="button" className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#acc${item.id}`}>
                                                                    <icon.Settings size="18" />
                                                                </span>
                                                                <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                                    <icon.Edit size="18" />
                                                                </span>
                                                                <span type="button" className="badge bg-danger mx-1"
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: "Yes, delete it!",
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                axios.delete(`/role/${item.id}`).then(() => {
                                                                                    getData();
                                                                                })
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <icon.Trash size="18" />
                                                                </span>
                                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title" id="editcategory">Edit</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                            </div>
                                                                            <form onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                const formData = new FormData(e.target);
                                                                                const data = Object.fromEntries(formData);
                                                                                axios.put(`/role/${item.id}`, data).then(() => {
                                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                                    toast.success('Updated Successfully');
                                                                                    getData();
                                                                                })
                                                                            }} >
                                                                                <div className="modal-body">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Role Name</label>
                                                                                        <input type="text" className="form-control" name="title" placeholder="Role Name" defaultValue={item.title} required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    <button type="submit" className="btn btn-primary">Update</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal fade" id={`acc${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title" id="editcategory">Permissions</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                            </div>
                                                                            <form onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                let acc = [];
                                                                                const formData = new FormData(e.target);
                                                                                for (let [name, value] of formData) {
                                                                                    if (e.target[name].checked) {
                                                                                        acc.push(value);
                                                                                    }
                                                                                }
                                                                                axios.put(`/permision/${item.id}`, { access: acc.toString() }).then(() => {
                                                                                    window.$(`#acc${item.id}`).modal('hide');
                                                                                    toast.success('Updated Successfully');
                                                                                    getData();
                                                                                })
                                                                            }} >
                                                                                <div className="modal-body">
                                                                                    <div className="row">
                                                                                        {accessItem.filter(j => j !== item.access).map((i, x) => (
                                                                                            <div key={x} className="col-md-6">
                                                                                                <div className="form-check">
                                                                                                    <input className="form-check-input" name={`x-${x}`} type="checkbox" defaultValue={i} id={`check-${x}`} defaultChecked={item.access.includes(i)} />
                                                                                                    <label className="form-check-label" htmlFor={`check-${x}`}>{i}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    <button type="submit" className="btn btn-primary">Update</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Roles;