import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from 'comma-number';
import Barcode from "../../../JsBarcode";


const Orders = ({ icon, acc }) => {

    const [data, setData] = useState([]);
    const [branch, setBranch] = useState([]);
    const [products, setProducts] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/order');
        setData(data);
        const { data: branch } = await axios.get('/branch');
        setBranch(branch)
        const { data: products } = await axios.get('/product');
        setProducts(products.map((item) => {
            return {
                value: item.id,
                order_price: item.order_price,
                label: item.product_name,
            };
        }));

    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);

    const [currentProduct, setCurrentProduct] = useState([])

    if (!acc.includes('Main')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Orders</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Order List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Order</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="neworder">New Order</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/order`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Branch</label>
                                                                <select className="form-select" name="branch_id" required>
                                                                    {branch.map((i, x) => {
                                                                        return (
                                                                            <option key={x} value={i.id}>{i.branch_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Products</label>
                                                                <Select options={products} name="product_id" placeholder="Select Product" onChange={(e) => { $('#order_price').val(e.order_price) }} required />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Qty</label>
                                                                <input type="number" step="any" className="form-control" name="qty" placeholder="0.00" defaultValue={0.00} required />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Order Price</label>
                                                                <input type="number" step="any" className="form-control" id="order_price" name="order_price" placeholder="0.00" defaultValue={0} required />
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <label className="form-label">Note</label>
                                                                <textarea className="form-control" name="note" placeholder="Any Note ..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Barcode</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                        <th>Branch</th>
                                        <th>Note</th>
                                        <th>Action By</th>
                                        <th>option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.product_name}</td>
                                                <td><Barcode value={item.barcode} /></td>
                                                <td>{item.qty}</td>
                                                <td>{commaNumber(item.order_price)}$</td>
                                                <td>{commaNumber(item.total_price)}$</td>
                                                <td>{item.branch_name}</td>
                                                <td>{item.note ? item.note : '-'}</td>
                                                <td>{item.username}</td>
                                                <td>
                                                    <span type="button" className="badge bg-dark mx-1" data-bs-toggle="modal" data-bs-target={`#trans${item.id}`}>
                                                        <icon.Repeat size="18" />
                                                    </span>
                                                    <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`} onClick={() => { setCurrentProduct({ value: item.product_id, label: item.product_name }) }}>
                                                        <icon.Edit size="18" />
                                                    </span>
                                                    <span type="button" className="badge bg-danger"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Are you sure?',
                                                                text: "You won't be able to revert this!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: "Yes, delete it!",
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/order/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                        <icon.Trash size="18" />
                                                    </span>
                                                    <div className="modal fade" id={`trans${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="neworder">Transfer: {item.product_name}</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    if (item.qty <= data.trans_qty) {
                                                                        toast.error(`this Qty not available !`)
                                                                    } else {
                                                                        axios.put(`/trans/${item.id}`, data).then(() => {
                                                                            window.$(`#trans${item.id}`).modal('hide');
                                                                            toast.success('Transfered Successfully');
                                                                            getData();
                                                                        })
                                                                    }
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <input type="hidden" name="product_id" defaultValue={item.product_id} />
                                                                        <input type="hidden" name="order_price" defaultValue={item.order_price} />
                                                                        <input type="hidden" name="branch_name" defaultValue={item.branch_name} />
                                                                        <input type="hidden" name="username" defaultValue={item.username} />
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Available Qty</label>
                                                                            <input type="number" step="any" className="form-control" name="available_qty" defaultValue={item.qty} readOnly />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Qty to Transfer</label>
                                                                            <input type="number" step="any" className="form-control" name="trans_qty" placeholder="0.00" defaultValue={0.00} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Transfer To</label>
                                                                            <select className="form-select" name="branch_id" required>
                                                                                {branch.filter(i => i.id !== item.branch_id).map((i, x) => (
                                                                                    <option key={x} value={i.id}>{i.branch_name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Update</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="neworder">Edit Order: {item.product_name}</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/order/${item.id}`, data).then(() => {
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                        toast.success('Updated Successfully');
                                                                        getData();
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="row">
                                                                            <div className="col-md-6 mb-3">
                                                                                <label className="form-label">Branch</label>
                                                                                <select className="form-select" name="branch_id" defaultValue={item.branch_id} required>
                                                                                    {branch.map((i, x) => {
                                                                                        return (
                                                                                            item.branch_id === i.id ? <option key={x} selected value={i.id}>{i.branch_name}</option> : <option key={x} value={i.id}>{i.branch_name}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-6 mb-3">
                                                                                <label className="form-label">Products</label>
                                                                                <Select options={products} name="product_id" placeholder="Select Product" value={currentProduct} onChange={(e) => {
                                                                                    $('#edit_order_price').val(e.order_price);
                                                                                    setCurrentProduct({ value: e.value, label: e.label });
                                                                                }} required />
                                                                            </div>
                                                                            <div className="col-md-6 mb-3">
                                                                                <label className="form-label">Qty</label>
                                                                                <input type="number" step="any" className="form-control" name="qty" placeholder="0.00" defaultValue={item.qty} required />
                                                                            </div>
                                                                            <div className="col-md-6 mb-3">
                                                                                <label className="form-label">Order Price</label>
                                                                                <input type="number" step="any" className="form-control" id="edit_order_price" name="order_price" placeholder="0.00" defaultValue={item.order_price} required />
                                                                            </div>
                                                                            <div className="col-md-12 mb-3">
                                                                                <label className="form-label">Note</label>
                                                                                <textarea className="form-control" name="note" placeholder="Any Note ..." defaultValue={item.note} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Update</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Orders;