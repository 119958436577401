import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

const Sidebar = ({ icon, acc }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(null)
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            } else {
                let acc = res.data.userInfo.access.split(',')
                setAuth(
                    <ul className="nav">
                        {acc.includes('Main') &&
                            <>
                                <li className="nav-item nav-category">Main</li>
                                <li className="nav-item">
                                    <Link to="/orders" className="nav-link">
                                        <icon.Airplay className="link-icon" />
                                        <span className="link-title">Order List</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/sales" className="nav-link">
                                        <icon.ShoppingCart className="link-icon" />
                                        <span className="link-title">Sales List</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/products" className="nav-link">
                                        <icon.Layers className="link-icon" />
                                        <span className="link-title">Products</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/categories" className="nav-link">
                                        <icon.GitBranch className="link-icon" />
                                        <span className="link-title">Categories</span>
                                    </Link>
                                </li>
                            </>
                        }

                        {acc.includes('Report') &&
                            <>
                                <li className="nav-item nav-category">Report</li>
                                <li className="nav-item">
                                    <Link to="/report/order-summary" className="nav-link">
                                        <icon.FileText className="link-icon" />
                                        <span className="link-title">Order Summary</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/report/sale-summary" className="nav-link">
                                        <icon.CheckCircle className="link-icon" />
                                        <span className="link-title">Sale Summary</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/report/store-balance" className="nav-link">
                                        <icon.Server className="link-icon" />
                                        <span className="link-title">Product Stock</span>
                                    </Link>
                                </li>
                            </>
                        }
                        {acc.includes('General') &&
                            <>
                                <li className="nav-item nav-category">General</li>
                                <li className="nav-item">
                                    <Link to="/branches" className="nav-link">
                                        <icon.Share2 className="link-icon" />
                                        <span className="link-title">Branch</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/users" className="nav-link">
                                        <icon.Users className="link-icon" />
                                        <span className="link-title">Users</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/customers" className="nav-link">
                                        <icon.Users className="link-icon" />
                                        <span className="link-title">Customers</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/roles" className="nav-link">
                                        <icon.Flag className="link-icon" />
                                        <span className="link-title">Role</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/general" className="nav-link">
                                        <icon.Settings className="link-icon" />
                                        <span className="link-title">Site Info</span>
                                    </Link>
                                </li>
                            </>
                        }
                        {acc.includes('Site Setting') &&
                            <>
                                <li className="nav-item nav-category">Site Settings</li>
                                <li className="nav-item">
                                    <Link to="/sliders" className="nav-link">
                                        <icon.Image className="link-icon" />
                                        <span className="link-title">Sliders</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ads" className="nav-link">
                                        <icon.Archive className="link-icon" />
                                        <span className="link-title">Ads</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/clients" className="nav-link">
                                        <icon.Bookmark className="link-icon" />
                                        <span className="link-title">Clients</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/services" className="nav-link">
                                        <icon.Gift className="link-icon" />
                                        <span className="link-title">Services</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/socials" className="nav-link">
                                        <icon.Link2 className="link-icon" />
                                        <span className="link-title">Social Link</span>
                                    </Link>
                                </li>
                            </>
                        }
                    </ul>
                )
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    EASY<span>LONDON</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                {auth}
            </div>
        </nav>
    );
}

export default Sidebar;