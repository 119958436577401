import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import Barcode from "../../JsBarcode";

const Products = ({ icon, acc }) => {

    const [data, setData] = useState([]);
    const [category, setCategory] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/product');
        setData(data);
        const { data: category } = await axios.get('/category');
        setCategory(category.map((item) => {
            return {
                value: item.id,
                label: item.category_name,
            };
        }));

    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };


    const [selectedFiles, setSelectedFiles] = useState([]);

    const uploadImage = async (e) => {
        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append("files[" + index + "]", file);
        });
        try {
            const { data } = await axios.post("/upload-images", formData);
            setSelectedFiles(data);
            console.log(data)
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const [currentCategory, setCurrentCategory] = useState([])
    const [currentProductid, setCurrentProductid] = useState(null)
    const [currentImages, setCurrentImages] = useState([])

    // const [content, setContent] = useState({});
    // const handleContentChange = (name, value) => {
    //     setContent(prevContent => ({ ...prevContent, [name]: value }));
    // }

    const generateRandomValue = () => Math.floor(Math.random() * 1000000000).toString();
    const [barcodeValue, setBarcodeValue] = useState(generateRandomValue());
    const handleGenerateClick = () => {
        setBarcodeValue(generateRandomValue());
    };


    if (!acc.includes('Main')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Product List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Product</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newproduct">New Product</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form id="from" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/product`, data).then((res) => {
                                                        setCurrentProductid(res.data)
                                                        getData();
                                                        e.target.reset();
                                                        setBarcodeValue(generateRandomValue());
                                                        window.$(`#new`).modal('hide');
                                                        window.$('#from').trigger("reset");
                                                        window.$(`#gallery`).modal('show');
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Category</label>
                                                            <Select options={category} name="category_id" placeholder="Select Category" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Product Name</label>
                                                            <input type="text" className="form-control" name="product_name" placeholder="Product Name" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <span className="form-label">Barcode (Optional)</span>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="barcode" placeholder="Barcode will appear here" value={barcodeValue} onChange={(e) => setBarcodeValue(e.target.value)} />
                                                                <span type="button" className="input-group-text" onClick={handleGenerateClick}><icon.Shuffle /></span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">content</label>
                                                            <textarea id="content" className="form-control" name="content" placeholder="Content" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Order Price</label>
                                                            <input type="number" step="any" className="form-control" name="order_price" placeholder="0.00" defaultValue={0.00} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Sale Price</label>
                                                            <input type="number" step="any" className="form-control" name="sale_price" placeholder="0.00" defaultValue={0.00} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Image</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Next</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="gallery" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newproduct">Add Product Images</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    axios.post(`/product-image`, { images: selectedFiles, product_id: currentProductid }).then(() => {
                                                        window.$(`#gallery`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                        e.target.reset();
                                                        setSelectedFiles([]);
                                                    }).catch(err => {
                                                        console.error("Error submitting form:", err);
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Image (Optional)</label>
                                                            <input type="file" className="form-control" onChange={uploadImage} accept="application/pdf, image/*" multiple />
                                                            <div className="row mt-3">
                                                                {selectedFiles.map((file, index) => (
                                                                    <div className="col-md-3" key={index}>
                                                                        <img className="img-fluid rounded" src={file} alt={`uploaded-img-${index}`} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th></th>
                                            <th>Product</th>
                                            <th>Bardcode</th>
                                            <th>Category</th>
                                            <th>Order Price</th>
                                            <th>Sale Price</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={item.image} /></td>
                                                    <td>{item.product_name}</td>
                                                    <td><Barcode value={item.barcode} /></td>
                                                    <td>{item.category_name}</td>
                                                    <td>{item.order_price}</td>
                                                    <td>{item.sale_price}</td>
                                                    <td>
                                                        <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`} onClick={() => {
                                                            setCurrentCategory({ value: item.category_id, label: item.category_name })
                                                            setCurrentProductid(item.id)
                                                            setBarcodeValue(item.barcode)
                                                            axios.get(`/product-image/${item.id}`).then((res) => {
                                                                setCurrentImages(res.data)
                                                            })
                                                        }}>
                                                            <icon.Edit size="18" />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mx-1"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: "Yes, delete it!",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/product/${item.id}`).then((res) => {
                                                                            getData();
                                                                            if (res.data === 'x') {
                                                                                toast.error('unable to delete, because this listed on order or sales')
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </span>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="editproduct">Edit {item.product_name}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/product/${item.id}`, data).then(() => {
                                                                            getData();
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            window.$(`#editgallery`).modal('show');
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Category</label>
                                                                                <Select options={category} name="category_id" placeholder="Select Category" value={currentCategory} onChange={(e) => {
                                                                                    setCurrentCategory({ value: e.value, label: e.label });
                                                                                }} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Product Name</label>
                                                                                <input type="text" className="form-control" name="product_name" placeholder="Product Name" defaultValue={item.product_name} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <span className="form-label">Barcode (Optional)</span>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" name="barcode" placeholder="Barcode will appear here" value={barcodeValue} onChange={(e) => setBarcodeValue(e.target.value)} />
                                                                                    <span type="button" className="input-group-text" onClick={handleGenerateClick}><icon.Shuffle /></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Content</label>
                                                                                <textarea id="content" className="form-control" name="content" placeholder="Content" defaultValue={item.content} required />
                                                                            </div>
                                                                            <div className="md-6 mb-3">
                                                                                <label className="form-label">Order Price</label>
                                                                                <input type="number" step="any" className="form-control" name="order_price" placeholder="0.00" defaultValue={item.order_price} />
                                                                            </div>
                                                                            <div className="md-6 mb-3">
                                                                                <label className="form-label">Sale Price</label>
                                                                                <input type="number" step="any" className="form-control" name="sale_price" placeholder="0.00" defaultValue={item.sale_price} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Image</label>
                                                                                <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                                <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.image} required />
                                                                                <img className="img-fluid rounded mt-2" src={`../../${item.image}`} alt={item.title} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                            <button type="submit" className="btn btn-primary">Next</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal fade" id="editgallery" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="newproduct">Add Product Images</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        axios.post(`/product-image`, { images: selectedFiles, product_id: currentProductid }).then(() => {
                                                                            window.$(`#editgallery`).modal('hide');
                                                                            toast.success('Added Successfully');
                                                                            getData();
                                                                        }).catch(err => {
                                                                            console.error("Error submitting form:", err);
                                                                        });
                                                                    }}>
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Image</label>
                                                                                <input type="file" className="form-control" onChange={uploadImage} accept="application/pdf, image/*" multiple />
                                                                                <div className="row mt-3">
                                                                                    {currentImages.map((file, index) => (
                                                                                        <div className="col-md-3" key={file.id || index}>
                                                                                            <img className="img-fluid rounded" src={file.image} alt={`uploaded-img-${index}`} onClick={() => {
                                                                                                axios.delete(`/product-image/${file.id}`).then(() => {
                                                                                                    setCurrentImages(prevImages => prevImages.filter(img => img.id !== file.id));
                                                                                                }).catch(error => {
                                                                                                    console.error('Error deleting image:', error);
                                                                                                });
                                                                                            }}
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                            <button type="submit" className="btn btn-primary">Submit</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Products;