import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Users = ({ icon, acc }) => {

    const [data, setData] = useState([]);
    const [branch, setBranch] = useState([]);
    const [role, setRole] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/user');
        setData(data);
        const { data: branch } = await axios.get('/branch');
        setBranch(branch)
        const { data: role } = await axios.get('/role');
        setRole(role)
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);

    if (!acc.includes('General')) {
        return <div className="page-content">  <div className="alert alert-danger" role="alert"> <h1>Permission Denied !</h1></div>  </div>
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Users List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New User</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newuser">New User</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/user`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Username</label>
                                                            <input type="text" className="form-control" name="username" placeholder="Username" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Password</label>
                                                            <input type="password" className="form-control" name="password" placeholder="Password" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Branch</label>
                                                            <select className="form-select" name="branch_id" required>
                                                                {branch.map((i, x) => {
                                                                    return (
                                                                        <option key={x} value={i.id}>{i.branch_name}</option>
                                                                    )
                                                                })}
                                                                <option value={0}>All</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Role</label>
                                                            <select className="form-select" name="role_id" required>
                                                                {role.map((i, x) => {
                                                                    return (
                                                                        <option key={x} value={i.id}>{i.title}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Username</th>
                                            <th>Branch</th>
                                            <th>Role</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.branch}</td>
                                                    <td>{item.role}</td>
                                                    <td>
                                                        <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mx-1"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: "Yes, delete it!",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/user/${item.id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </span>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="editproduct">Edit {item.username}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/user/${item.id}`, data).then(() => {
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            toast.success('Updated Successfully');
                                                                            getData();
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Username</label>
                                                                                <input type="text" className="form-control" name="username" placeholder="Username" defaultValue={item.username} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Password</label>
                                                                                <input type="password" className="form-control" name="password" placeholder="Password" />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Branch</label>
                                                                                <select className="form-select" name="branch_id" required>
                                                                                    {branch.map((i, x) => {
                                                                                        return (
                                                                                            item.branch_id === i.id ? <option key={x} selected value={i.id}>{i.branch_name}</option> : <option key={x} value={i.id}>{i.branch_name}</option>
                                                                                        )
                                                                                    })}
                                                                                    <option value={0}>All</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Role</label>
                                                                                <select className="form-select" name="role_id" required>
                                                                                    {role.map((i, x) => {
                                                                                        return (
                                                                                            item.role_id === i.id ? <option key={x} selected value={i.id}>{i.title}</option> : <option key={x} value={i.id}>{i.title}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                            <button type="submit" className="btn btn-primary">Update</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Users;