import React, { useRef, useEffect } from 'react';
import JsBarcode from 'jsbarcode';

const Barcode = ({ value }) => {
    const barcodeRef = useRef(null);

    useEffect(() => {
        if (value) {
            JsBarcode(barcodeRef.current, value, {
                format: "CODE128",
                lineColor: "#000",
                background:false,
                width: 2,
                height: 30,
                displayValue: false,
            });
        }
    }, [value]);

    return value ? <svg ref={barcodeRef}></svg> : <span>No Barcode</span>;
};

export default Barcode;
